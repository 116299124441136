import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import unilodgeIcon from "../../../images/mie/case-study/unilodge.svg";
import wineryIcon from "../../../images/mie/case-study/citywinery.svg";
import rivercitylabsIcon from "../../../images/mie/case-study/rivercitylabs.svg";
import morrowIcon from "../../../images/mie/case-study/morrow_street.svg";
import victoriaUniIcon from "../../../images/mie/case-study/victoria_university.svg";


const MIECaseStudy = () => {
  const data = [
    {
      desc: "Increasing customer engagement for City Winery Brisbane...",
      img: wineryIcon,
      link: "https://cdn.vostro.cloud/content/casestudy-publicwifi-citywinery.pdf"
    },
    {
      desc: "Engineering a high-speed shared workspace for Australian Computer Society...",
      img: rivercitylabsIcon,
      link: "https://cdn.vostro.cloud/content/casestudy-coworking-acs.pdf"
    },
    {
      desc: "Trusted by Australia's largest student accommodation provider...",
      img: unilodgeIcon,
      link: "https://cdn.vostro.cloud/content/casestudy-student-unilodge.pdf"
    },
    {
      desc: "We just did something a little exciting down at Morrow Street...",
      img: morrowIcon,
      link: "https://cdn.vostro.cloud/content/casestudy-hotel-essence.pdf"
    },
    {
      desc: "Delivering high-speed wireless at Victoria University...",
      img: victoriaUniIcon,
      link: "https://cdn.vostro.cloud/content/casestudy-student-vicuni.pdf"
    },
  ];
  return (
    <div className="case-study py-5">
      <Container>
        <Row>
          <Col>
            <div className="case-title mb-5">
              {"Read our case studies"}
            </div>
          </Col>
        </Row>
        <Row className="modified-row">
          {data.map(({img, link, desc}) => {
            return (
              <Col xs={12} lg={true} className="no-padding" key={link}>
                <a href={link} target="_blank" rel="noopener" className="case">
                  <img className="case-img mb-4 block-center" src={img} alt={`${desc} icon`} />
                  <div className="case-desc block-center mb-4">
                    {desc}
                  </div>
                  <button target="_blank" rel="noopener" className="block-center btn btn-read-more btn-green btn-long">
                    {"Read more"}
                  </button>
                </a>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
}

export default MIECaseStudy;