import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col, Button } from "react-bootstrap";

import buildToRentImage from "../../../images/mie/menu/buildtorent_button.png";
import studentAccommodationImage from "../../../images/mie/menu/studentacco_button.png";
import coWorkingImage from "../../../images/mie/menu/coworking_button.png";
import publicWifiImage from "../../../images/mie/menu/public_wifi_button.png";
import hotelImage from "../../../images/mie/menu/hotel_button.png";
import residentialImage from "../../../images/other-mie/residential.png";

const MIEHub = () => {
  return (
    <Container className="hub mie-hub py-5">
      <Row>
        <Col>
          <div className="title mb-4">
            {"Internet Environment Solutions"}
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={3}>
          <Link to="/residential-fibre-network/">
            <div className="hub-container">
              <div className="tall" style={{
                backgroundImage: `url(${residentialImage})`,
              }} />
              <span className="hub-text">{"Residential"}</span>
              <div className="hover-info">
                <div className="title mb-2">
                  {"Residential"}
                </div>
                <div className="subtitle mb-3">
                  {"VostroNet delivers tailored internet services to residential developments on a wholesale basis."}
                </div>
                <Button variant="green" size="short">
                  {"Explore"}
                </Button>
              </div>
            </div>
          </Link>
        </Col>
        <Col xs={12} lg={6}>
          <Row>
            <Col xs={12}>
              <Link to="/student-accommodation/">
                <div className="hub-container">
                  <div className="long" style={{
                    backgroundImage: `url(${studentAccommodationImage})`,
                  }} />
                  <span className="hub-text">{"Student Accommodation"}</span>
                  <div className="hover-info">
                    <div className="title mb-2">
                      {"Student Accommodation"}
                    </div>
                    <div className="subtitle mb-3">
                      {"We are experts at delivering lightning fast internet to 25,000+ connections in student accommodation."}
                    </div>
                    <Button variant="green" size="short">
                      {"Explore"}
                    </Button>
                  </div>
                </div>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={6}>
              <Link to="/co-working/">
                <div className="hub-container">
                  <div className="short" style={{
                    backgroundImage: `url(${coWorkingImage})`,
                  }} />
                  <span className="hub-text">{"Co-Working"}</span>
                  <div className="hover-info">
                    <div className="title mb-2">
                      {"Co-Working"}
                    </div>
                    <div className="subtitle mb-3">
                      {"Enhanced functionality and security for users within a shared workspace environment."}
                    </div>
                    <Button variant="green" size="short">
                      {"Explore"}
                    </Button>
                  </div>
                </div>
              </Link>
            </Col>
            <Col xs={12} lg={6}>
              <Link to="/public-wifi/">
                <div className="hub-container">
                  <div className="short" style={{
                    backgroundImage: `url(${publicWifiImage})`,
                  }} />
                  <span className="hub-text">{"Public Wi-Fi"}</span>
                  <div className="hover-info">
                    <div className="title mb-2">
                      {"Public Wi-Fi"}
                    </div>
                    <div className="subtitle mb-3">
                      {"Stay connected wherever you go on VostroNet’s seamless public Wi-Fi solution and analytics platform."}
                    </div>
                    <Button variant="green" size="short">
                      {"Explore"}
                    </Button>
                  </div>
                </div>
              </Link>
            </Col>
          </Row>
        </Col>
        <Col xs={12} lg={3}>
          <Row>
            <Col xs={12}>
              <Link to="/hotel/">
                <div className="hub-container">
                  <div className="short" style={{
                    backgroundImage: `url(${hotelImage})`,
                  }} />
                  <span className="hub-text">{"Hotels"}</span>
                  <div className="hover-info">
                    <div className="title mb-2 mb-2">
                      {"Hotels"}
                    </div>
                    <div className="subtitle mb-3">
                      {"Keep guests connected with VostroNet’s end-to-end hotel network solution, powered by our innovative software platform."}
                    </div>
                    <Button variant="green" size="short">
                      {"Explore"}
                    </Button>
                  </div>
                </div>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Link to="/build-to-rent/">
                <div className="hub-container">
                  <div className="short" style={{
                    backgroundImage: `url(${buildToRentImage})`,
                  }} />
                  <span className="hub-text">{"Build-to-Rent"}</span>
                  <div className="hover-info">
                    <div className="title mb-2">
                      {"Build-to-Rent"}
                    </div>
                    <div className="subtitle mb-3">
                      {"VostroNet delivers tailored internet services to build-to-rent developments on a wholesale basis."}
                    </div>
                    <Button variant="green" size="short">
                      {"Explore"}
                    </Button>
                  </div>
                </div>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default MIEHub;