import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import Banner from "../components/sections/banner"
import Blog from "../components/sections/blogs"
import InformationSection from "../components/sections/informational-section";
import MIEHub from "../components/sections/hubs/MIE-hub";
import MIECaseStudy from "../components/sections/case-study/MIE-case-study";

import wirelessInternetIcon from "../images/mie/info/wireless_internet.svg";
import wirelessEquipmentIcon from "../images/mie/info/wireless_equipment.svg";
import networkPlanningIcon from "../images/mie/info/network_planning.svg";

import { graphql, useStaticQuery } from "gatsby"

const bgQuery = graphql`query {
  file(relativePath: { eq: "headers/managed_intenv_header.png"}) {
    childImageSharp {
      fluid(quality: 90, maxWidth: 4160) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
}`


const ManagedInternetEnvironments = () => {
  const bgData = useStaticQuery(bgQuery);
  return (<Layout title="Internet Environments">
    <SEO title="Internet Environments - VostroNet">
      <meta name="description" content="Dedicated Internet for Unique Environments powered by our innovative Q2 software platform on an advanced network."/>
    </SEO>
    <Banner className="font-white" bgData={bgData} data={{
      title: {
        name: "Internet Environments",
      },
      subtitles: [
        {
          name: `The team at VostroNet are experts in installing network solutions to a diverse range of environments.`,
        },
      ],
      buttonData: {
        name: "Explore Environments",
      },
    }}/>
    <MIEHub />
    <InformationSection data={{
      title: "Software-defined Solutions",
      subtitle: "VostroNet leads the industry in software-defined internet solutions, harnessing software optimise performance and user experience. Our entire network is monitored and maintained in real-time through our industry-leading Q2 Software Platform™, proactively resolving issues and keeping our customers happy.",
      subData: [
        {
          title: "Wireless Internet Experts",
          subtitle: "We design, build and operate Wi-Fi networks, with thousands of users across Asia-Pacific.",
          img: wirelessInternetIcon,
        },
        {
          title: "Network Planning and Wireless Reach",
          subtitle: "VostroNet designs Wi-Fi environments using advanced coverage simulation. All deployments are followed by an on-site assessment to ensure quality coverage in all locations",
          img: networkPlanningIcon,
        },
        {
          title: "Harnessing the latest Wireless Equipment",
          subtitle: "We Design our networks to ensure users remain connected. Complementing our thorough network planning, we also deploy the latest release premium quality Aruba wireless access points.",
          img: wirelessEquipmentIcon,
        },
      ],
    }} />
    <MIECaseStudy />
    <Blog />
  </Layout>);
}

export default ManagedInternetEnvironments;
